/* Fix for breadcrumb counters */
.breadcrumb-item,
.breadcrumb-link,
.no-counter {
  counter-increment: none !important;
  counter-reset: none !important;
}

.breadcrumb-item::before,
.breadcrumb-link::before,
.no-counter::before,
.breadcrumb-item::after,
.breadcrumb-link::after,
.no-counter::after {
  content: none !important;
}

/* Add this to your breadcrumb-fix.css file */
[data-slot="breadcrumb-list"] {
  list-style-type: none !important;
}

[data-slot="breadcrumb-list"] li {
  list-style-type: none !important;
  counter-increment: none !important;
}

/* Target the ::marker pseudo-element which creates the numbers */
[data-slot="breadcrumb-list"] li::marker {
  content: none !important;
}
