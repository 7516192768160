/* Import fonts */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

@custom-variant dark (&:is(.dark *));

/* Tailwind directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* Font variables */
    --font-inter: "Inter", sans-serif;

    /* Base colors */
    --background: oklch(1 0 0);
    --foreground: oklch(0.145 0 0);

    /* Card colors */
    --card: oklch(1 0 0);
    --card-foreground: oklch(0.145 0 0);

    /* Popover colors */
    --popover: oklch(1 0 0);
    --popover-foreground: oklch(0.145 0 0);

    /* Primary colors */
    --primary: oklch(0.205 0 0);
    --primary-foreground: oklch(0.985 0 0);

    /* Secondary colors */
    --secondary: oklch(0.97 0 0);
    --secondary-foreground: oklch(0.205 0 0);

    /* Muted colors */
    --muted: oklch(0.97 0 0);
    --muted-foreground: oklch(0.556 0 0);

    /* Accent colors */
    --accent: oklch(0.97 0 0);
    --accent-foreground: oklch(0.205 0 0);

    /* Destructive colors */
    --destructive: oklch(0.577 0.245 27.325);
    --destructive-foreground: oklch(0.985 0 0);

    /* Border, input, and ring colors */
    --border: oklch(0.922 0 0);
    --input: oklch(0.922 0 0);
    --ring: oklch(0.708 0 0);

    /* Chart colors */
    --chart-1: oklch(0.646 0.222 41.116);
    --chart-2: oklch(0.6 0.118 184.704);
    --chart-3: oklch(0.398 0.07 227.392);
    --chart-4: oklch(0.828 0.189 84.429);
    --chart-5: oklch(0.769 0.188 70.08);

    /* Border radius */
    --radius: 0.625rem;

    /* Sidebar variables */
    --sidebar-width: 256px;
    --sidebar-width-collapsed: 64px;
    --sidebar-width-icon: 48px;
    --spacing-4: 1rem;
    --sidebar-background: oklch(0.98 0 0);
    --sidebar-foreground: oklch(0.261 0.005 300);
    --sidebar-primary: oklch(0.1 0.006 300);
    --sidebar-primary-foreground: oklch(0.98 0 0);
    --sidebar-accent: oklch(0.959 0.005 300);
    --sidebar-accent-foreground: oklch(0.1 0.006 300);
    --sidebar-border: oklch(0.91 0.013 280);
    --sidebar-ring: oklch(0.598 0.255 262.32);

    /* Animation variables */
    --animate-accordion-down: accordion-down 0.2s ease-out;
    --animate-accordion-up: accordion-up 0.2s ease-out;
    --animate-slide-in-right: slideInRight 0.3s ease-out;
  }

  .dark {
    /* Base colors */
    --background: oklch(0.145 0 0);
    --foreground: oklch(0.985 0 0);

    /* Card colors */
    --card: oklch(0.145 0 0);
    --card-foreground: oklch(0.985 0 0);

    /* Popover colors */
    --popover: oklch(0.145 0 0);
    --popover-foreground: oklch(0.985 0 0);

    /* Primary colors */
    --primary: oklch(0.985 0 0);
    --primary-foreground: oklch(0.205 0 0);

    /* Secondary colors */
    --secondary: oklch(0.269 0 0);
    --secondary-foreground: oklch(0.985 0 0);

    /* Muted colors */
    --muted: oklch(0.269 0 0);
    --muted-foreground: oklch(0.708 0 0);

    /* Accent colors */
    --accent: oklch(0.269 0 0);
    --accent-foreground: oklch(0.985 0 0);

    /* Destructive colors */
    --destructive: oklch(0.396 0.141 25.723);
    --destructive-foreground: oklch(0.985 0 0);

    /* Border, input, and ring colors */
    --border: oklch(0.269 0 0);
    --input: oklch(0.269 0 0);
    --ring: oklch(0.439 0 0);

    /* Chart colors */
    --chart-1: oklch(0.488 0.243 264.376);
    --chart-2: oklch(0.696 0.17 162.48);
    --chart-3: oklch(0.769 0.188 70.08);
    --chart-4: oklch(0.627 0.265 303.9);
    --chart-5: oklch(0.645 0.246 16.439);

    /* Sidebar variables - dark mode */
    --sidebar-background: oklch(0.1 0.006 300);
    --sidebar-foreground: oklch(0.959 0.005 300);
    --sidebar-primary: oklch(0.48 0.215 263);
    --sidebar-primary-foreground: oklch(1 0 0);
    --sidebar-accent: oklch(0.159 0.004 300);
    --sidebar-accent-foreground: oklch(0.959 0.005 300);
    --sidebar-border: oklch(0.159 0.004 300);
    --sidebar-ring: oklch(0.598 0.255 262.32);
  }

  * {
    @apply border-border outline-none;
  }

  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
    font-family: var(--font-inter);
    letter-spacing: -0.011em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /* Typography styles based on Shadcn UI */
  h1,
  .h1 {
    @apply scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl;
    letter-spacing: -0.03em;
  }

  h2,
  .h2 {
    @apply scroll-m-20 border-b pb-2 text-3xl font-semibold tracking-tight transition-colors first:mt-0;
    letter-spacing: -0.02em;
  }

  h3,
  .h3 {
    @apply scroll-m-20 text-2xl font-semibold tracking-tight;
    letter-spacing: -0.015em;
  }

  h4,
  .h4 {
    @apply scroll-m-20 text-xl font-semibold tracking-tight;
    letter-spacing: -0.015em;
  }

  p,
  .p {
    @apply leading-7 [&:not(:first-child)]:mt-6;
  }

  blockquote {
    @apply mt-6 border-l-2 border-slate-300 pl-6 italic text-slate-800 dark:border-slate-600 dark:text-slate-200;
  }

  /* Prevent list bullets in navigation */
  nav ul,
  .nav-list,
  .navigation ul,
  aside ul {
    @apply list-none m-0 p-0;
  }

  nav li,
  .nav-list li,
  .navigation li,
  aside li {
    @apply m-0 p-0;
  }

  /* Content lists (keep bullets for content) */
  .content ul,
  article ul {
    @apply my-6 ml-6 list-disc [&>li]:mt-2;
  }

  ol {
    @apply my-6 ml-6 list-decimal [&>li]:mt-2;
  }

  .lead {
    @apply text-xl text-slate-700 dark:text-slate-300;
  }

  .large {
    @apply text-lg font-semibold;
  }

  .small {
    @apply text-sm font-medium leading-none;
  }

  .muted {
    @apply text-sm text-slate-500 dark:text-slate-400;
  }
}

@layer utilities {
  .blur-image {
    filter: blur(8px);
  }
}

/* Animation keyframes */
@keyframes accordion-down {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes accordion-up {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

:root {
  --sidebar: hsl(0 0% 98%);
  --sidebar-foreground: hsl(240 5.3% 26.1%);
  --sidebar-primary: hsl(240 5.9% 10%);
  --sidebar-primary-foreground: hsl(0 0% 98%);
  --sidebar-accent: hsl(240 4.8% 95.9%);
  --sidebar-accent-foreground: hsl(240 5.9% 10%);
  --sidebar-border: hsl(220 13% 91%);
  --sidebar-ring: hsl(217.2 91.2% 59.8%);
}

.dark {
  --sidebar: hsl(240 5.9% 10%);
  --sidebar-foreground: hsl(240 4.8% 95.9%);
  --sidebar-primary: hsl(224.3 76.3% 48%);
  --sidebar-primary-foreground: hsl(0 0% 100%);
  --sidebar-accent: hsl(240 3.7% 15.9%);
  --sidebar-accent-foreground: hsl(240 4.8% 95.9%);
  --sidebar-border: hsl(240 3.7% 15.9%);
  --sidebar-ring: hsl(217.2 91.2% 59.8%);
}

@theme inline {
  --color-sidebar: var(--sidebar);
  --color-sidebar-foreground: var(--sidebar-foreground);
  --color-sidebar-primary: var(--sidebar-primary);
  --color-sidebar-primary-foreground: var(--sidebar-primary-foreground);
  --color-sidebar-accent: var(--sidebar-accent);
  --color-sidebar-accent-foreground: var(--sidebar-accent-foreground);
  --color-sidebar-border: var(--sidebar-border);
  --color-sidebar-ring: var(--sidebar-ring);
}

@layer base {
  * {
    @apply border-border outline-none;
  }
  body {
    @apply bg-background text-foreground;
  }
}

/* Mapbox Geocoding API styles */
.mapbox-geocoding-suggestions {
  z-index: 100 !important;
}

/* React DatePicker styles */
.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block !important;
  width: 100% !important;
}

.react-datepicker__input-container input {
  width: 100% !important;
}
